
import { defineComponent } from 'vue';
export default defineComponent({
	props: ['menu'],
	data() {
		return { showMenu: false };
	},
	methods: {
		onClick(v: string): void {
			this.$emit('to-menu', v);
		},
		goToTop(): void {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}
});
