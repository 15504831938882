
import { useMeta } from 'vue-meta';
import { defineComponent } from 'vue';
import { auth } from '@/firebase';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
	setup() {
		useMeta({
			title: 'Home'
		});
	},
	name: 'App',
	components: { Header, Footer },
	created() {
		auth.onAuthStateChanged((user) => {
			if (!user) return;
			this.store.methods.login();
			this.store.methods.setUser(user.email || '');
		});
		window.addEventListener('scroll', this.dynamicNavbar);
	},
	unmounted() {
		window.removeEventListener('scroll', this.dynamicNavbar);
	},
	data() {
		return {
			SITE_NAME: 'wchr-aun',
			hideNavbar: false
		};
	},
	methods: {
		dynamicNavbar(): void {
			if (window.scrollY > 50) {
				this.hideNavbar = true;
			} else {
				this.hideNavbar = false;
			}
		}
	}
});
