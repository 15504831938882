
import { defineComponent, PropType } from 'vue';
import { EventList } from '@/models/timeline';

export default defineComponent({
	name: 'Timeline',
	props: {
		list: {
			type: Array as PropType<EventList[]>,
			required: true
		}
	},
	data() {
		let extend = new Map();
		Array.from(new Set(this.list.map((v) => v.cover))).forEach((v) => {
			extend.set(v, false);
		});
		let events = this.list.flatMap((v) =>
			v.end
				? [
						{ ...v, position: 'right', parent: false, end: false },
						{ ...v, grid: 0 }
				  ]
				: v
		);
		return { extend, events };
	},
	computed: {
		resetNeeded(): boolean {
			return !Array.from(this.extend.values()).every((v) => !v);
		}
	},
	methods: {
		toggle(cover: string): void {
			this.extend.set(cover, !this.extend.get(cover));
		},
		gridLength(item: EventList): number {
			return !this.extend.get(item.cover) && item.end ? 2 : item.grid * 2;
		},
		resetToggle(): void {
			for (const [key, _] of this.extend.entries()) this.extend.set(key, false);
		}
	}
});
