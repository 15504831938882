
import { defineComponent } from 'vue';

export default defineComponent({
	data() {
		return { domainClicks: 0 };
	},
	computed: {
		loggedIn() {
			return this.store.state.loggedIn;
		},
		user() {
			return this.store.state.user;
		}
	},
	methods: {
		clickDomain() {
			this.domainClicks++;
			if (this.$router.currentRoute.value.path === '/login') {
				this.$router.push('/');
				return;
			}
			if (this.domainClicks > 3) {
				this.domainClicks = 0;
				this.$router.push('/login');
				return;
			}
		}
	}
});
